import React from 'react';

import Layout from '@narative/gatsby-theme-novela/src/components/Layout';
import Section from '@narative/gatsby-theme-novela/src/components/Section';
import SEO from '@narative/gatsby-theme-novela/src/components/SEO';
import Headings from '@narative/gatsby-theme-novela/src/components/Headings';

function NotFoundPage() {
	return (
		<Layout>
			<SEO />
			<Section>
				<div style={{ marginTop: '100px' }}>
					<Headings.h1>
						"इस पन्ने को पकड़ पाना मुश्किल ही नहीं, <br /> नामुमकिन है|"
					</Headings.h1>
					<div className="Image__Medium">
						<img src={require('./404.gif')} alt="loading..." />
					</div>
				</div>
			</Section>
		</Layout>
	);
}

export default NotFoundPage;
